
import * as requests from '@/api/Filter'

function attemptGetFilterList (pagination) {
  return requests.getFilterList(pagination)
    .then(filtersList => {
      return {
        data: filtersList.data.data,
        firstPage: filtersList.data.firstPage,
        lastPage: filtersList.data.lastPage,
        nextPage: filtersList.data.nextPage,
        previousPage: filtersList.data.previousPage,
        actualPage: filtersList.data.actualPage,
        total: filtersList.data.total
      }
    })
}

function attemptGetFilter (id) {
  return requests.getFilter(id)
    .then(response => {
      return response.data
    })
}

function attemptCreateFilter (form) {
  return requests.createFilter(form)
    .then(response => {
      return response
    })
}

function attemptUpdateFilter (form) {
  return requests.updateFilter(form)
    .then(response => {
      return response
    })
}

function attemptRunFilter (data) {
  return requests.runFilter(data)
    .then(response => {
      return response.data
    })
}

function attemptToggleActiveFilter (id) {
  return requests.toggleActiveFilter(id)
    .then(response => {
      return response.data
    })
}

export const services = {
  attemptGetFilterList,
  attemptGetFilter,
  attemptCreateFilter,
  attemptUpdateFilter,
  attemptRunFilter,
  attemptToggleActiveFilter
}
