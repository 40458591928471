import { http } from '@/support/http'
import { createQuery } from '@/support/utils/paginatorQueryBuilder'
import qs from 'qs'

export const getFilterList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/filters?${queryString}`)
}

export const getFilter = (id) => {
  return http.get(`api/filters/${id}`)
}

export const createFilter = (form) => {
  return http.post('api/filters', form)
}

export const updateFilter = ({ id, data }) => {
  return http.put(`api/filters/${id}`, data)
}

export const runFilter = (data) => {
  const queryString = qs.stringify({ entity_ids: data.entityIds || [] })

  return http.get(`api/filters/${data.id}/run?${queryString}`)
}

export const toggleActiveFilter = (id) => {
  return http.patch(`api/filters/${id}/toggle`)
}
