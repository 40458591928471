<script>
import { mapActions } from 'vuex'
import { services } from '../services'
import { HEADER_TAB_LINKS } from '../../../constants'

export default {
  name: 'ListPage',

  components: {
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Action: () => import('@/components/general/Action'),
    Datatable: () => import('@/components/general/Datatable'),
    Dropdown: () => import('@/components/general/Dropdown'),
    Pagination: () => import('@/components/general/Pagination'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    FilterList: () => import('@/components/general/FilterList'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    Tabs: () => import('@/components/general/Tabs')
  },

  data () {
    return {
      pagination: {
        query: {},
        filter: {},

        order: {
          active: 'desc',
          name: 'asc'
        },

        limit: 12,
        page: 1,
        lastPage: null
      },

      filtersList: []
    }
  },

  computed: {
    mayWrite () {
      return this.getContextPermission('filters') === 'write'
    }
  },

  watch: {
    'pagination.page' () {
      this.getFilterList()
    }
  },

  created () {
    this.setup()
    this.getFilterList()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'setPagination'
    ]),

    setup () {
      this.HEADER_TAB_LINKS = HEADER_TAB_LINKS
    },

    prevPage () {
      this.pagination.page--
    },

    nextPage () {
      this.pagination.page++
    },

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.pagination.lastPage
    },

    createNewFilter () {
      this.$router.push({ name: 'notifications.filters.manage' })
    },

    searchFilter (search) {
      this.pagination.query = {
        name: search
      }

      if (this.pagination.page === 1) {
        this.getFilterList()
      } else {
        this.pagination.page = 1
      }
    },

    edit (id) {
      this.$router.push({
        name: 'notifications.filters.manage',
        params: {
          id: id
        }
      })
    },

    verify (filterId) {
      this.setFetching(true)

      services.attemptRunFilter({ id: filterId })
        .then((response) => {
          if (response.length === 0) {
            this.setFeedback({
              message: this.$t('notifications.filters:feedback.verify.success.empty'),
              textColor: '#fff',
              backgroundColor: '#f9a825'
            })
          } else {
            this.setFeedback({
              message: this.$t('notifications.filters:feedback.verify.success.total'),
              textColor: '#fff',
              backgroundColor: '#28a745'
            })
          }
        })
        .catch((e) => {
          let message = this.$t('global.error')

          if (e.data.error.message === 'filter_execution_error') {
            message = this.$t('notifications.filters:feedback.verify.error', { detail: e.data.error.detail })
          }

          this.setFeedback({
            message: message,
            textColor: '#fff',
            backgroundColor: '#ff6347'
          })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    toggle (filterId, toggleFlag) {
      this.setFetching(true)

      services.attemptToggleActiveFilter(filterId)
        .then(() => {
          this.getFilterList()

          if (!toggleFlag) {
            this.setFeedback({ message: this.$t('notifications.filters:feedback.toggle.activate.success') })
          } else {
            this.setFeedback({ message: this.$t('notifications.filters:feedback.toggle.deactivate.success') })
          }
        })
        .catch((e) => {
          let message = this.$t('global.error')

          if (e.data.error.message === 'cant_activate_filter_execution_error') {
            message = this.$t('notifications.filters:feedback.toggle.execution.error')
          }

          if (e.data.error.message === 'cant_change_mediation_plan_associated') {
            message = this.$t('notifications.filters:feedback.toggle.execution.error.using-filter')
          }

          this.setFeedback({
            message: message,
            textColor: '#fff',
            backgroundColor: '#ff6347'
          })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    getFilterList () {
      this.setFetching(true)

      services.attemptGetFilterList(this.pagination)
        .then(response => {
          this.pagination.lastPage = response.lastPage
          this.filtersList = response.data
        })
        .finally(() => {
          this.setFetching(false)
        })
    }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('notifications:header.title')"
      :description="$t('notifications:header.description')"
      background="/assets/images/themes/default/png/management_banner.png"
    >
      <Tabs
        slot="tabs"
        dark
        :links="HEADER_TAB_LINKS"
      />
    </ContentHeader>
    <FilterList>
      <Action
        v-if="mayWrite"
        slot="button"
        primary
        large
        fixed-width
        type="button"
        :text="$t('notifications.filters:btn.add')"
        @click="createNewFilter"
      />

      <FilterListSearch
        slot="search"
        :hide-error-details="true"
        @search="searchFilter"
      />
    </FilterList>
    <div class="center notifications-content">
      <div
        v-if="filtersList.length > 0"
        class="center"
      >
        <Datatable :items="filtersList">
          <template slot="thead">
            <tr>
              <th
                class="th"
                width="300"
              >
                {{ $t('notifications.filters.list:datatable.header.1') }}
              </th>
              <th
                class="th"
                width="100"
              >
                {{ $t('notifications.filters.list:datatable.header.2') }}
              </th>
              <th
                class="th"
                width="100"
              >
                {{ $t('notifications.filters.list:datatable.header.3') }}
              </th>
              <th
                class="th"
                width="60"
              >
                {{ $t('notifications.filters.list:datatable.header.4') }}
              </th>
              <th
                class="th"
                width="40"
              />
            </tr>
          </template>
          <template
            slot="tbody"
            slot-scope="props"
          >
            <tr>
              <td class="td">
                <span class="td-text">{{ props.item.name }}</span>
              </td>

              <td class="td">
                <span class="td-text">{{ props.item.metadata.contextDescription }}</span>
              </td>

              <td class="td">
                <span class="td-text">{{ props.item.metadata.conditionDescription }}</span>
              </td>

              <td class="td">
                <span
                  v-if="!!props.item.active"
                  class="td-tag active-ribbon"
                >{{ $t('global.status:active') }}</span>
                <span
                  v-else
                  class="td-tag inactive-ribbon"
                >{{ $t('global.status:inactive') }}</span>
              </td>

              <td
                class="td td-actions"
                width="40"
              >
                <Dropdown
                  icon="dots-vertical"
                  right
                >
                  <DropdownLink
                    v-if="mayWrite"
                    :text="$t('global:edit')"
                    @click="edit(props.item.id)"
                  />
                  <DropdownLink
                    v-show="props.item.fullSql !== null"
                    :text="$t('global:verify.execution')"
                    @click="verify(props.item.id)"
                  />
                  <DropdownLink
                    v-if="!props.item.active && mayWrite"
                    :text="$t('global:activate')"
                    @click="toggle(props.item.id, props.item.active)"
                  />
                  <DropdownLink
                    v-else-if="mayWrite"
                    :text="$t('global:deactivate')"
                    @click="toggle(props.item.id, props.item.active)"
                  />
                </Dropdown>
              </td>
            </tr>
          </template>
        </Datatable>
        <Pagination
          :active-page="pagination.page"
          :page-count="pagination.lastPage"
          @firstPage="firstPage"
          @lastPage="lastPage"
          @nextPage="nextPage"
          @previousPage="prevPage"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="../../../assets/scss/Notification.scss"></style>
